<template>
    <div v-if='model && !model.is_null'>
        <form @submit='save' @change='formIsDirty'>
            <div class='grid p-fluid formgrid mt-6'>
                <div class='field col-12 md:col-6'>
                    <div class="p-inputgroup">
                    <span class='p-float-label'>
                        <InputText id='plain_slug'
                                   type='text'
                                   v-model="v$.model.slug.$model"
                                   :class="{'p-invalid': v$.model.slug.$errors.length}"
                        />
                        <Button label='Fix' class="p-button-warning" title='Correggi SLUG' @click='model.slug = sluggify(model.slug)'/>
                        <label for='plain_slug'>Slug</label>
                    </span>
                    </div>
                </div>
            </div>

            <div class='grid p-fluid formgrid mt-3'>
                <div class='field col-12  md:col-8'>
                    <span class='p-float-label'>
                        <InputText id='name'
                                   type='text'
                                   v-model="v$.model.name.$model"
                                   :class="{'p-invalid': v$.model.name.$errors.length}"
                        />
                        <label for='name'>Nome</label>
                    </span>
                </div>
            </div>

            <div class='grid p-justify-center mt-4'>
                <div class='col-12 text-center'>
                    <SaveButton @click='save' :load='loading'/>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('Cont/Speakers');
import useVuelidate from '@vuelidate/core'

import model from './model';

import Navigations from '../../../../mixins/navigations';
import Notifications from '../../../../mixins/notifications';

export default {
    mounted(){
        this.model = this.row;
    },
    setup () {
        return { v$: useVuelidate() }
    },
    validations () {
        return model.modelValidation;
    },
    computed: {
        ...mapGetters(['row', 'loading']),
    },
    watch: {
        row(n){
            this.model = n;
        }
    },
    methods: {
        ...mapActions(['savePlain','createPlain']),
        ...mapMutations(['formIsDirty', 'formIsClean']),
        async save () {
            const result = await this.v$.$validate()
            if (!result) {
                this.ShowError("Errore", "Alcuni campi del form non sono corretti");
                return
            }
            const save = (this.model.id !== 0) ? this.savePlain : this.createPlain;
            const changeRoute = (this.model.id === 0);

            save(this.model).then( resp => {
                this.formIsClean();
                if (changeRoute){
                    this.navigateToId(resp.id).then( () => {
                        this.ShowSuccess('Ottimo', 'Publisher creato con successo');
                    });
                    return;
                }
                this.ShowSuccess('Ottimo', 'Publisher modificato con successo');

            }).catch(err => {
                this.ShowStandardError(err.response);
            });
        },
    },
    mixins: [
        Navigations,
        Notifications,
    ]
};
</script>
