import tools from '../../../../libs/tools';
import { required } from '@vuelidate/validators';

const model = {
    id: 0,
    slug: "",
    name: ""
};
const translation = {};

export default {
    ...tools.models.apiCommons(model, translation, ""),
    modelValidation: {
        model: {
            slug: {
                required,
                slug: tools.models.validators.SLUG
            },
            name: {
                required
            }
        }
    },
    translationValidation: {}
};
