export default {
    methods: {
        beforeRouteLeaveCheck(to, from, next){
            if (!this.dirtyForm) {
                this.setRow(null);
                next();
                return;
            }
            this.$confirm.require({
                message: 'Sei sicuro di voler abbandonare la pagina?',
                header: 'Non hai salvato le modifiche',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.formIsClean();
                    this.setRow(null);
                    next();
                },
                reject: () => {
                    //callback to execute when user rejects the action
                    next(false);
                }
            });
        },
        beforeRouteUpdateCheck (to, from, next){
            if (!this.dirtyForm) {
                next();
                return;
            }
            this.$confirm.require({
                message: 'Sei sicuro di voler cambiare TAB?',
                header: 'Non hai salvato le modifiche',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.formIsClean();
                    next();
                },
                reject: () => {
                    //callback to execute when user rejects the action
                    next(false);
                }
            });
        }
    }
}
